import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import BuildingManagerEdit from "@components/building-managers/Edit"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "building-managers.edit"
}

const BuildingManagerAddPage = (props) => (
  <>
    <Seo title="Edit Building Manager" />
    <div className="main-wrapper">
      <Sidebar menuActive="building-managers" 
        action="Edit"/>
      <BuildingManagerEdit 
        menu="Edit Building Managers" 
        parentPath="building-managers" 
        pageType="Edit"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default BuildingManagerAddPage;
