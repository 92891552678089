import React, { useEffect, useState } from "react";
import { Row, Col, Form, Alert, Button } from "react-bootstrap";
import { HandleChange, HandleAttachmentField, CheckFieldValidity, ClearAttachmentField } from "../../utility/Form";
import CommonEditItemComp from "../common/Edit";
import Select from 'react-select';
import { get, set, isEmpty, find } from "lodash";
import FormFooter from "../common/FormFooter"

const EditFormControls = (props) => {
  const [DefaultFields, SetDefaultFields] = useState({
    is_show_on_site: null,
    picture: {},
  });

  const [pictureUrl, setPictureUrl] = useState("");
  const [designation, setDesignation] = useState("");
  const GetUploadedUrl = async (event) => {
    try {
      const UploadedResult = await HandleAttachmentField(event, { ...props });
      set(DefaultFields, 'picture', UploadedResult);
      setPictureUrl(UploadedResult.url);
    } catch (error) {
      console.log('AddBuildingManager CommonAddItemComp GetUploadedUrl error', error, event, props)
    }
  }

  const HandleMultiSelect = (event, name, copyInAddParam) => {
    switch (name) {
      case 'designation':
        setDesignation(event)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if(props?.editItem?.picture) {
      setPictureUrl(props.editItem.picture.url || "");
      set(DefaultFields, 'picture', props.editItem.picture);
    };
    props?.editItem?.designation && setDesignation({ value: props.editItem.designation, label: props.editItem.designation });
    props?.editItem?.is_show_on_site && setIsShowOnSite(props.editItem.is_show_on_site);
    set(DefaultFields, 'is_show_on_site', props?.editItem?.is_show_on_site);
  }, [props.editItem]);

  const [formError, setFormError] = useState({});
  const [isFormSaveTriggered, setIsFormSaveTriggered] = useState(false);
  const CheckFormFieldValidity = (event) => {
    const FieldError = CheckFieldValidity(event);
    setFormError({ ...formError, ...FieldError });
  }

  const HandleChangeFunc = (event) => {
    HandleChange(event);
    CheckFormFieldValidity(event);
  }

  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      setIsFormSaveTriggered(true);
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'edit-building-manger-form',
        ...additionalParams,
        fields: { ...DefaultFields }
      });
      let selectBoxErrorObjTemp = {};
      if (get(Result, 'formSubmit.hasError') || isEmpty(selectBoxErrorObjTemp)) {
        setFormError(Result.formSubmit.error);
      } else {
        setIsFormSaveTriggered(false);
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  const SaveCurrentTab = (event) => {
    FormSubmitFunc(event, {
      isSubmit: true,
      enableValidation: true
    });
  }

  const [isShowOnSite, setIsShowOnSite] = useState(false);
  const HandleCheckBoxValue = (event) => {
    set(DefaultFields, `${event.target.name}`, event.target.checked);
    if (event.target.name === 'is_show_on_site') {
      setIsShowOnSite(event.target.checked)
    }
  }

  return (
    <>
      <Form id="edit-building-manger-form" noValidate onSubmit={(event) => SaveCurrentTab(event)}>
        {
          isFormSaveTriggered && ((find(formError, function (error) {
            return (error.hasError)
          }))) && <Alert key="danger" variant="danger">
            Highlighted fields are required
          </Alert>
        }
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Manager Name *</label>
              <input type="text" name="name" className={`form-control${isFormSaveTriggered && formError?.["name"]?.hasError ? ' pu-has-control-error' : ''}`}
                onChange={(event) => HandleChangeFunc(event)}
                required={true}
                placeholder="Enter manager name here"
                defaultValue={props?.editItem?.name} />
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Designation</label>
              <div className="custom-selectbox-form selectbox-dropdown">
                <Select
                  name="designation"
                  options={[
                    { value: "", label: "Please select designation" },
                    { value: "Sales Manager", label: "Sales Manager" },
                    { value: "Manager", label: "Manager" },
                  ]}
                  placeholder="Please select designation"
                  classNamePrefix={"select-opt"}
                  isSearchable={false}
                  value={designation}
                  onChange={event => HandleMultiSelect(event, 'designation')}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Telephone</label>
              <input type="text" className="form-control"
                name="telephone"
                placeholder="Enter telephone number here"
                defaultValue={props.editItem.telephone}
                onChange={(event => HandleChange(event))} />
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Mobile</label>
              <input type="text" className="form-control" placeholder="Enter mobile number here"
                name="mobile"
                defaultValue={props.editItem.mobile}
                onChange={(event => HandleChange(event))}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>E-mail Address</label>
              <input type="text" className="form-control" placeholder="Enter e-mail address here"
                name="email_address"
                defaultValue={props.editItem.email_address}
              />
            </div>
          </Col>
          <Col lg={5} className="mb-4">
            <div className="d-flex justify-content-between flex-wrap">
              <div className="form-wrapper w-100">
                <label>Picture</label>
                {/* NOTE: Every input file field shoud have name field it mailyb used for upload the file in specific path */}
                <input type="file" name="picture" className="form-control file-attach"
                  accept="image/*"
                  onChange={(event) => GetUploadedUrl(event, 'custom-file1')} id="custom-file1"
                  placeholder="Click to attach" />
              </div>
              {
                pictureUrl &&
                <div className="preview-img w-100 mt-3 mb-3">
                  <a href={pictureUrl} target="_blank"><img src={pictureUrl} className="img-fluid" style={{ width: "100px", height: "70px", objectFit: "cover" }} /></a>
                </div>
              }
            </div>
          </Col>
          <Col lg={1} className="mb-4">
            <label> </label>
            <Button className="btn btn-default" onClick={() => {
              ClearAttachmentField('custom-file1');
              setPictureUrl('');
              set(DefaultFields, 'picture', {});
            }}>Clear</Button>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-check dashboard-checkbox">
              <input className="form-check-input" type="checkbox" id="site"
                checked={isShowOnSite}
                name="is_show_on_site"
                onChange={event => HandleCheckBoxValue(event)} />
              <label className="form-check-label" htmlFor="site">Show On Site</label>
            </div>
          </Col>
        </Row>
        <FormFooter parentPath={props.parentPath}></FormFooter>
      </Form>
    </>
  )
}

const EditBuildingManager = (props) => {
  return <CommonEditItemComp PuFormControlsEdit={
    (HandleSubmitFunc, SetPublishActionAvailability, item) => {
      return (
        <>
          <EditFormControls HandleSubmitFunc={HandleSubmitFunc} {...props}
            editItem={item}
            SetPublishActionAvailability={SetPublishActionAvailability}
          />
        </>
      )
    }
  } {...props} />
}

export default EditBuildingManager;
